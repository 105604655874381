import React from "react";
import { Link } from "gatsby";
import CookieConsent from "react-cookie-consent";

import logo from "../img/logo-wimmic-gray.svg";
import facebook from "../img/social/facebook.svg";
import instagram from "../img/social/instagram.svg";
import twitter from "../img/social/twitter.svg";
import linkedin from "../img/social/linkedin.svg";
import { products } from "../constants/products";
import logoCelestia from "../img/celestia-group-gray.png";
import EuropeanUnion from "./EuropeanUnion";

const STYLE_LOGO = { width: "12em", opacity: 0.4 };
const STYLE_SOCIAL_NETWORK = { width: "1em", height: "1em" };
const FOOTER_1 = { padding: "5px 0 20px" };
const STYLE_ADDRESS = {
  // backgroundImage: "url(/img/world-map.png)",
  // backgroundRepeat: "no-repeat",
  // backgroundSize: "contain",
  // paddingLeft: 20
};
const LOGO_CELESTIA = {
  maxHeight: 42,
  marginBottom: 5,
  marginRight: 10,
  marginLeft: 5,
};
const LogoFooter = () => <img src={logo} alt="Wimmic" style={STYLE_LOGO} />;

const MenuFooter = class extends React.Component {
  render() {
    return (
      <ul className="menu-list">
        <li>
          <Link to="/" className="navbar-item">
            Home
          </Link>
        </li>
        <li>
          <Link className="navbar-item" to="/products">
            Products
          </Link>
        </li>
        <li>
          <Link className="navbar-item" to="/design-service">
            Design
          </Link>
        </li>
        <li>
          <Link className="navbar-item" to="/about">
            About us
          </Link>
        </li>
        <li>
          <Link className="navbar-item" to="/contact">
            Contact
          </Link>
        </li>
      </ul>
    );
  }
};

const MenuProductsFooter = class extends React.Component {
  render() {
    return (
      <ul className="menu-list">
        {products.map((p, i) => (
          <li key={i}>
            <Link to={"/products#product" + (i + 1)} className="navbar-item">
              {p.name}
            </Link>
          </li>
        ))}
      </ul>
    );
  }
};

const MenuSocialFooter = class extends React.Component {
  render() {
    return (
      <div className="social" style={{ marginLeft: "auto" }}>
        <a
          title="Linkedin Wimmic"
          href="https://www.linkedin.com/company/wireless-innovative-mmic-s-l-wimmic/about/"
          target="_blank"
        >
          <img
            src={linkedin}
            alt="LinkedIn Wimmic"
            style={STYLE_SOCIAL_NETWORK}
          />
        </a>
        <a
          title="Twitter Wimmic"
          href="https://twitter.com/WWimmic"
          target="_blank"
        >
          <img
            className="fas fa-lg"
            src={twitter}
            alt="Twitter Wimmic"
            style={STYLE_SOCIAL_NETWORK}
          />
        </a>
      </div>
    );
  }
};
const CopyRight = class extends React.Component {
  render() {
    return (
      <div className="has-background-black-bis copyright">
        <div
          className="container"
          style={{ display: "flex", alignItems: "center" }}
        >
          © 2019 WIMMIC - Wireless Innovative MMIC.
          <br className="just-mobile" />
          <br className="just-mobile" /> Part of{" "}
          <a
            href="http://celestia-tech.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              style={LOGO_CELESTIA}
              src={logoCelestia}
              alt="Celestia Technologies Group"
            />
          </a>
          <br className="just-mobile" /> All Rights Reserved.
          <br className="just-mobile" />
          <a
            href="/quality-policy"
            rel="noopener noreferrer"
            style={{ marginLeft: 10, color: "#999" }}
          >
            Quality Policy
          </a>
          <br className="just-mobile" />
          <a
            href="/privacy-policy"
            rel="noopener noreferrer"
            style={{ marginLeft: 10, color: "#999" }}
          >
            Privacy and Cookies Policy
          </a>
          .
          <MenuSocialFooter />
        </div>
      </div>
    );
  }
};

const Footer = class extends React.Component {
  render() {
    return (
      <React.Fragment>
        <EuropeanUnion />
        <footer className="">
          {/* <footer className="footer has-background-black"> */}
          {/* <div className="container has-background-black" style={FOOTER_1} /> */}
          <CopyRight />
        </footer>

        <CookieConsent
          location="bottom"
          buttonText="AGREE"
          cookieName="wimmicCookieBanner"
          acceptOnScrollPercentage={25}
          acceptOnScroll
          style={{
            background: "#2B373B",
            fontSize: 13,
            paddingLeft: 15,
            paddingRight: 15,
          }}
          buttonStyle={{
            backgroundColor: "rgb(0, 101, 164)",
            color: "#FFF",
            fontSize: "13px",
          }}
          expires={150}
        >
          WIMMIC uses its own and third-party cookies to analyze the web traffic
          and improve its navigation. If you continue browsing or accept its
          use, then it is considered that you accept the use of cookies. For mor
          information, check our{" "}
          <a href="/privacy-policy#cookies">Cookies Policy</a>
        </CookieConsent>
      </React.Fragment>
    );
  }
};

export default Footer;

import React from "react";
import { Link } from "gatsby";
import github from "../img/github-icon.svg";
import logoIcono from "../img/icono-wimmic.svg";
import logoTexto from "../img/wimmic-integrated-circuits.svg";

const LOGO = {
  height: "52px",
};

const Navbar = class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      navBarActiveClass: "",
    };
  }

  toggleHamburger = () => {
    // toggle the active boolean in the state
    this.setState(
      {
        active: !this.state.active,
      },
      // after state has been updated,
      () => {
        // set the class in state for the navbar accordingly
        this.state.active
          ? this.setState({
              navBarActiveClass: "is-active",
            })
          : this.setState({
              navBarActiveClass: "",
            });
      }
    );
  };

  render() {
    const areWeInNews =
      typeof window != "undefined"
        ? window.location.href.includes("/news/")
        : false;
    return (
      <nav
        className="navbar is-transparent"
        role="navigation"
        aria-label="main-navigation"
      >
        <div className="container">
          <div className="navbar-brand">
            <Link to="/" className="navbar-item" title="Logo">
              <img
                src={logoIcono}
                alt="Wimmic Logo"
                style={{ ...LOGO, marginRight: "7px" }}
              />
              <img
                src={logoTexto}
                alt="Wimmic Integrated Circuits"
                style={LOGO}
              />
            </Link>
            {/* Hamburger menu */}
            <div
              className={`navbar-burger burger ${this.state.navBarActiveClass}`}
              data-target="navMenu"
              onClick={() => this.toggleHamburger()}
            >
              <span />
              <span />
              <span />
            </div>
          </div>
          <div
            id="navMenu"
            className={`navbar-menu ${this.state.navBarActiveClass}`}
          >
            <div className="navbar-start has-text-centered" />
            <div className="navbar-end has-text-centered">
              <Link
                activeClassName="currentpage"
                className="navbar-item"
                to="/"
              >
                HOME
              </Link>
              <Link
                activeClassName="currentpage"
                className="navbar-item"
                to="/products"
              >
                PRODUCTS
              </Link>
              <Link
                activeClassName="currentpage"
                className="navbar-item"
                to="/design-service"
              >
                DESIGN
              </Link>
              <Link
                activeClassName="currentpage"
                className={"navbar-item " + (areWeInNews ? "currentpage" : "")}
                to="/news"
              >
                NEWS
              </Link>
              <Link
                activeClassName="currentpage"
                className="navbar-item"
                to="/about"
              >
                ABOUT US
              </Link>
              <Link
                activeClassName="currentpage"
                className="navbar-item"
                to="/contact"
              >
                CONTACT
              </Link>
            </div>
          </div>
        </div>
      </nav>
    );
  }
};

export default Navbar;

export const products = [
  {
    home: {
      name: "HPA.",
      description: "Ka-band High Power Amplifier.",
    },
    name: "Power Amplifier",
    text:
      "This MMIC is a High Power Amplifier designed for Ku-band applications, specifically for a frequency range of 16 - 17 GHz. The power amplifier provides a saturated power of 39 dBm, a power added efficiency around 30 % and a linear gain rising to 25 dB. The chip is matched to 50 ohms, so the addition of external DC Blocks and RF port matching is not required.",
    image: "/img/wimmic-power-amplifier.jpg",
    imageDetail: "/img/wimmic-power-amplifier.jpg",
    table: [
      { key: "Parameter", value: "Val." },
      { key: "Frequency [GHz]", value: "16-17" },
      { key: "PSAT [dBm]", value: "39" },
      { key: "Power Added Efficiency [%]", value: "28" },
      { key: "Linear Power [dBm]", value: "38" },
      { key: "Input/Output Return Loss [dB]", value: "-12" },
      { key: "Gain @ PSAT [dB]", value: "20" },
      { key: "Linear Gain [dB]", value: "25" },
      { key: "Supply [V]", value: "10" },
      { key: "Area [µm2]", value: "3500x5000" },
      { key: "Process", value: "GaN OMMIC" },
    ],
  },
  {
    home: {
      name: "LNA",
      description: "K-band Low Noise Amplifier. Noise Figure (NF) 1 dB.",
    },
    name: "LNA",
    text:
      "This MMIC is a four-stage K-band Low-Noise Amplifier (LNA) that operates in the upper end of the K-band (25.5 GHz up to 27 GHz). It achieves a gain of 29.5 dB with 1 dB ripple, a Noise Figure (NF) as low as 1 dB and an Input Return Loss better than -10 dB across the band. The chip is matched to 50 ohms, therefore the addition of external DC Blocks and RF port matching is not required.",
    image: "/img/wimmic-lna-home.png",
    imageDetail: "/img/wimmic-lna.png",
    table: [
      { key: "Parameter", value: "Val." },
      { key: "Freq. Range [GHz]", value: "25.5-27" },
      { key: "Gain [dB]", value: "29.5 ±1" },
      { key: "Noise Figure [dB]", value: "1" },
      { key: "Input Return Loss [dB]", value: "-12" },
      { key: "Output Return Loss [dB]", value: "-20" },
      { key: "Supply [V]", value: "1.2" },
      { key: "Area [µm2]", value: "2500x1750" },
      { key: "Process", value: "GaAs 70 nm" },
    ],
  },
  {
    home: {
      name: "Mixer",
      description: "Ku-band SSB ​Sub-Harmonically Pumped Mixer",
    },
    name: "Mixer",
    text:
      "An SSB Sub-Harmonically Pumped Mixer. This circuit is intended for Ku-band applications, specifically to provide an output signal frequency range of 13.75- 14.5 GHz when applying a 1.5-2.7 GHz input signal and a 5.9-6.1 GHz Local Oscillator signal. The MMIC has a 13.9 dB conversion loss and a sideband rejection of 55.701 dBc. It is matched to 50 ohms, so the addition of external DC Blocks and RF port matching is not required.",
    image: "/img/wimmic-mixer-rectangle.jpg",
    imageDetail: "/img/wimmic-mixer-rectangle.jpg",
    table: [
      { key: "Parameter", value: "Val" },
      { key: "Input frequency [GHz]", value: "1.5-2.7" },
      { key: "LO frequency [GHz]", value: "5.9-6.1" },
      { key: "Output frequency [GHz]", value: "13.75-14.5" },
      { key: "Input signal power [dBm]", value: "-10" },
      { key: "LO signal power [dBm]", value: "20" },
      { key: "Losses [dB]", value: "13.974" },
      { key: "Sideband rejection [dBc]", value: "56" },
      { key: "LO signal power level @ output [dBm]", value: "-50" },
      { key: "Area [µm2]", value: "2740x1622" },
      { key: "Process", value: "GaN OMMIC" },
    ],
  },
  {
    home: {
      name: "Low Noise Amplifier",
      description: "This MMIC is a four-stage K-band Low-Noise Amplifier (LNA)",
    },
    name: "Low Noise Amplifier",
    text:
      "This MMIC is a four-stage K-band Low-Noise Amplifier (LNA) that operates in the upper end of the K-band (25.5 GHz up to 27 GHz). It achieves a gain of 31.7 dB with 1 dB ripple, a Noise Figure (NF) as low as 1.6 dB and an Input Return Loss better than -10 dB across the band. The chip is matched to 50 ohms, therefore the addition of external DC Blocks and RF port matching is not required.",
    image: "/img/wimmic-low-noise-amplifier.jpg",
    imageDetail: "/img/wimmic-low-noise-amplifier.jpg",
    table: [
      { key: "Parameter", value: "Val." },
      { key: "Freq. Range (GHz)", value: "25.5-27" },
      { key: "Gain [dB]", value: "31.7 ±1.5" },
      { key: "Noise Figure (dB)", value: "1.6" },
      { key: "Input Return Loss (dB)", value: "–10 " },
      { key: "Output Return Loss (dB)", value: "–5" },
      { key: "Supply [V]", value: "2" },
      { key: "Area [µm2]", value: "3200 x 1720" },
      { key: "Process", value: "GaAs UMS" },
    ],
  },
  {
    home: {
      name: "Temperature Sensor",
      description: "CMOS Temperature Sensor for on-chip thermal management",
    },
    name: "Temperature Sensor",
    text:
      "CMOS Temperature Sensor for on-chip thermal management. The core of the sensor is designed to present a high temperature sensitivity with a very low power consumption. A conventional pseudo-differential pair is employed as op-amp to regulate the current mirror biasing.",
    image: "/img/wimmic-temperature-sensor-home.jpg",
    imageDetail: "/img/wimmic-temperature-sensor.jpg",
    table: [
      { key: "Parameter", value: "Val." },
      { key: "Technology Process", value: "130 nm SiGe BiCMOS" },
      { key: "Supply (V)", value: "1.2" },
      { key: "Tº Range (ºC)", value: "0 ~ 100" },
      { key: "Tº Coefficient (mV/ºC)", value: "-2.1" },
      { key: "Inaccuracy (ºC)", value: "±3" },
      { key: "Calibration scheme", value: "One-point" },
      { key: "Chip size (μm2)", value: "434" },
      { key: "Power consumption (μW)", value: "102" },
      { key: "Process", value: "IHP SiGe BiCMOS" },
    ],
  },
];

import React from "react";
import PropTypes from "prop-types";

const STYLE_TEXT = {
  fontSize: "20px"
};
const ContactHero = ({
  text = "Want to learn how WIMMIC can help your company ?",
  link = "/contact",
  ctaText = "Contact us"
}) => (
  <section className="hero is-info contact-hero">
    <div className="hero-body">
      <div className="container content has-text-centered">
        <span className="h4 text" style={STYLE_TEXT}>
          {text}
          <br className="just-mobile" />
          <br className="just-mobile" />
          <a
            href={link}
            className="button is-inverted is-outlined is-primary contact-hero-cta"
          >
            {ctaText}
          </a>
        </span>
      </div>
    </div>
  </section>
);

ContactHero.propTypes = {};

export default ContactHero;

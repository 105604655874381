import React from "react";
import PropTypes from "prop-types";
import { StickyContainer } from "react-sticky";
import Sticky from "../components/Sticky";
import Fade from "react-reveal/Fade";

const STYLE_PRODUCT = {
  padding: "30px 10px",
  margin: "50px 0",
};
const ProductImage = ({ image, name, text }) => (
  <div className="column is-offset-1">
    <Fade>
      <img src={image} alt="" />
      <h2>{name}</h2>
      <p>{text}</p>
    </Fade>
    {/* <Table table={table} /> */}
  </div>
);
const Table = ({ table: [firstElement, ...table], horizontal = false }) => {
  return (
    <Fade delay={100} timeout={2000}>
      <div>
        {horizontal ? (
          <table className="table">
            <thead>
              <tr>
                {[firstElement, ...table].map((row, i) => (
                  <th key={i}>{row.key}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              <tr>
                {[firstElement, ...table].map((row, i) => (
                  <td key={i}>{row.value}</td>
                ))}
              </tr>
            </tbody>
          </table>
        ) : (
          <table className="table">
            <thead>
              {[firstElement].map((row, i) => (
                <tr key={i}>
                  <th key={i}>{row.key}</th>
                  <th key={i + 1}>{row.value}</th>
                </tr>
              ))}
            </thead>
            <tbody>
              {table.map((row, i) => (
                <tr key={i}>
                  <td key={i}>{row.key}</td>
                  <td key={i + 1}>{row.value}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </Fade>
  );
};
const Product = ({ name, text, image, table, right = false, index = 1 }) => (
  <div id={"product" + index} className="productWrapper">
    {/* <div className="columns">
      <h2 className="column is-offset-1" style={STYLE_TITLE}>
      {name}
      </h2>
    </div> */}
    <div className="product columns" style={STYLE_PRODUCT}>
      {!right && <ProductImage image={image} name={name} text={text} />}
      {Boolean(table) && (
        <StickyContainer className="column is-offset-1">
          <Sticky>
            {({ style }) => (
              <div style={style}>
                <Table table={table} />
              </div>
            )}
          </Sticky>
        </StickyContainer>
      )}
      {!!right && <ProductImage image={image} name={name} text={text} />}
    </div>
  </div>
);

Product.propTypes = {
  heading: PropTypes.string,
  text: PropTypes.string,
};

export default Product;

import React, { Component } from "react";
import { Sticky as StickyOriginal } from "react-sticky";
import { isMobile } from "react-device-detect";

export default class Sticky extends Component {
  render() {
    return isMobile ? (
      <div>{this.props.children(this.props)}</div>
    ) : (
      <StickyOriginal {...this.props} />
    );
  }
}

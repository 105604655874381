export const productsExtra = [
  {
    name: "GaN Driver Amplifier design and layout",
    text:
      "Custom four-stage Driver Amplifier using OMMIC’s D01GH technology and simulated, but not manufactured yet. The resulting amplifier can achieve a maximum gain of 50.5dB with input and output matching below -20 dB at 14 GHz.",
    imageDetail: "/img/wimmic-gan-driver-amplifier.jpg",
  },
  {
    name: "GaAs mixer design and layout",
    text:
      "GaAs version developed using OMMIC’s ED02AH technology. The resulting design achieves a good image rejection and acceptable gain losses.",
    imageDetail: "/img/wimmic-gas-mixer.jpg",
  },
  {
    name: "GaAs Driver Amplifier design and layout",
    text:
      "We have also designed a 5-stage GaAs ED02AH Ku-band Driver Amplifier up to the layout stage, obtaining a gain of 53.809 dB and an input and output matching below -18 dB at 14 GHz.",
    imageDetail: "/img/wimmic-gas-driver-amplifier.jpg",
  },
  {
    name:
      "Design and modelling of custom inductors in GaN on Si, GaAs and SiGe processes.",
    text:
      "At WIMMIC we have experience in the use of electro-magnetic simulators and in the design of custom inductors in different technologies. In this custom inductor catalogue we can find tapered octagonal and square inductors, solenoid inductors and many other options to suit the design’s requirements.",
    imageDetail: "/img/wimmic-gan-on-si-gaas-sige.jpg",
  },
  {
    name: "Radiation hardened circuits",
    text:
      "We have experience in the design of radiation hardened by design RF and communications circuits. We have performed several studies regarding the effect of heavy ions in semiconductor devices, both in CMOS and GaN processes. With the information obtained in these studies, we have developed RF circuits that are robust against single event transients (SETs). Specifically, we have focused on the design of low-noise amplifiers (LNAs) that include several mitigation techniques that make them inherently robust against radiation. We have also performed studies regarding the propagation of SETs in RF systems such as receivers.",
    imageDetail: "/img/wimmic-radiation-hardened.jpg",
  },
];

import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import ImageHero from "../components/ImageHero";
import Product from "../components/Product";
import { products } from "../constants/products";
import { productsExtra } from "../constants/products-extra";
import ContactHero from "../components/ContactHero";
import Fade from "react-reveal/Fade";

export const ProductPageTemplate = ({ childImageSharp, title }) => (
  <>
    <ImageHero heading={title} childImageSharp={childImageSharp} />
    <div className="content">
      <section className="section section--gradient">
        {/* <div className="columns">
              <div className="column is-7 is-offset-1">
                <h3 className="has-text-weight-semibold is-size-2">
                  {heading}
                </h3>
                <p>{description}</p>
              </div>
            </div> */}
        {products.map((p, i) => (
          <Product
            key={i}
            index={i + 1}
            name={p.name}
            text={p.text}
            image={p.imageDetail}
            table={p.table}
            right={i % 2 === 0 && false}
          />
        ))}
      </section>

      <section className="section section--gradient">
        <h2 className="porducts-extra-title">Other design capacities</h2>
        <div className="products-extra-grid">
          {productsExtra.map((p, i) => (
            <div key={p.name} className="column product-extra-home">
              <Fade delay={(i + 1) * 100} timeout={1300}>
                <section className="box">
                  <div className="has-text-centered">
                    <div
                      style={{
                        width: "100%",
                        display: "inline-block",
                      }}
                    >
                      <picture style={{ maxHeight: 150 }}>
                        <img src={p.imageDetail} />
                      </picture>
                    </div>
                  </div>
                  <b>{p.name}</b>
                  <p>{p.text}</p>
                </section>
              </Fade>
            </div>
          ))}
        </div>
      </section>
    </div>
    <ContactHero />
  </>
);

ProductPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  description: PropTypes.string,
  intro: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
  main: PropTypes.shape({
    heading: PropTypes.string,
    description: PropTypes.string,
    image1: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    image2: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    image3: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  }),
  testimonials: PropTypes.array,
  fullImage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  pricing: PropTypes.shape({
    heading: PropTypes.string,
    description: PropTypes.string,
    plans: PropTypes.array,
  }),
};

const ProductPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;
  return (
    <Layout>
      <ProductPageTemplate
        childImageSharp={frontmatter.image.childImageSharp}
        title={frontmatter.title}
        heading={frontmatter.heading}
        description={frontmatter.description}
        intro={frontmatter.intro}
        main={frontmatter.main}
        testimonials={frontmatter.testimonials}
        fullImage={frontmatter.full_image}
        pricing={frontmatter.pricing}
      />
    </Layout>
  );
};

ProductPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default ProductPage;

export const productPageQuery = graphql`
  query ProductPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        heading
        description
      }
    }
  }
`;

import React from "react";
import Fade from "react-reveal/Fade";
import imageGobCan from "../img/funds/gob-can.jpg";
import imageCanariasAvanza from "../img/funds/canarias-avanza.jpg";
import imageEuropeUnion from "../img/funds/europe-union.jpg";
import imageEuropeUnionNoText from "../img/funds/europe-union-no-text.jpg";

const ROOT = {};

const EuropeanUnion = ({}) => {
  return (
    <div className="europe-union container" style={ROOT}>
      <div className="section" style={{ padding: "1.5rem 0.5rem" }}>
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <div className="columns">
              <div className="column is-6" style={{ flex: 2 }}>
                <p>
                WIMMIC is a reality thanks to the European Union SMEs policy.
                Our company received co-funding from Horizon 2020 EU Framework Programme
                for Research and Innovation under "SME Instrument" programme, grant agreement
                No. 828346; and “Innovation in SMEs” (INNOSUP) programme, grant agreement
                No. 957151. WIMMIC was also awarded 70% co-funding through
                EATIC 2018 – ACIISI Programme (project No. EATIC2018010121),
                Government of the Canary Islands (the Government of the Canary Islands
                receives 85% co-funding from the European Regional Development Fund (ERDF)
                through ERDF Canary Islands Operational Programme 2014-2020).
                </p>
              </div>
              <div className="column">
                <div className="columns europe-logos">
                  <div className="column column-logo">
                    <img src={imageGobCan} />
                  </div>
                  <div className="column column-logo">
                    <img src={imageCanariasAvanza} />
                  </div>
                  <div className="column column-logo">
                    <img src={imageEuropeUnion} />
                  </div>
                  <div className="column column-logo">
                    <img src={imageEuropeUnionNoText} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

EuropeanUnion.propTypes = {};

export default EuropeanUnion;

import React from "react";
import PropTypes from "prop-types";
import Fade from "react-reveal/Fade";
import BackgroundImage from "gatsby-background-image";

const STYLE_BASE = {
  backgroundPosition: `top left`,
  backgroundAttachment: `fixed`,
  justifyContent: "flex-start",
  alignItems: "stretch",
};
const STYLES_PRESET = {
  default: {
    ...STYLE_BASE,
  },
  home: {
    ...STYLE_BASE,
    height: "85vh",
  },
};

const STYLES_TRANSPARENCY_BASE = {
  backgroundColor: "rgba(0,0,0,0.25)",
  color: "white",
  padding: "60px 20px 20px 20px",
  width: "100%",
  display: "flex",
  flexDirection: "row",
  // justifyContent: "center",
  alignItems: "flex-end",
};
const STYLES_TRANSPARENCY = {
  default: {
    ...STYLES_TRANSPARENCY_BASE,
  },
  home: {
    ...STYLES_TRANSPARENCY_BASE,
    backgroundColor: "rgba(0,0,0,0.05)",
  },
};
const STYLES_CONTAINER = {
  default: {
    paddingBottom: "50px",
  },
  home: {
    paddingBottom: "70px",
  },
};

const ImageHero = ({
  heading,
  subheading,
  childImageSharp,
  style = {},
  stylesTransparency = {},
  preset = "default",
}) => {
  const propsFade =
    preset === "default"
      ? { top: true, cascade: true, timeout: 2400 } //{ timeout: 3000 }
      : { top: true, cascade: true, timeout: 2400 };
  return (
    <BackgroundImage
      className={[
        "full-width-image",
        "margin-top-0",
        "image-hero",
        "preset-" + preset,
      ].join(" ")}
      style={{
        // backgroundImage: `url(${
        //   !!childImageSharp ? childImageSharp.fluid.src : image
        // })`,
        ...STYLES_PRESET[preset],
        ...style,
      }}
      fluid={childImageSharp.fluid}
    >
      <div style={{ ...STYLES_TRANSPARENCY[preset], ...stylesTransparency }}>
        <div className="container" style={STYLES_CONTAINER[preset]}>
          <h1 className="has-text-weight-bold is-size-3-mobile is-size-2-tablet is-size-1-widescreen image-hero-title">
            <Fade {...propsFade}>{heading}</Fade>
          </h1>
          <h3 className="has-text-weight-bold is-size-5-mobile is-size-5-tablet is-size-4-widescreen image-hero-subtitle">
            <Fade {...propsFade}>{subheading}</Fade>
          </h3>
        </div>
      </div>
    </BackgroundImage>
  );
};

ImageHero.propTypes = {
  heading: PropTypes.string,
  subheading: PropTypes.string,
};

export default ImageHero;
